<template>
  <!---start-wrap---->

  <!---start-content---->
  <div class="">
    <div class="wrap">
      <div id="main" role="main">
        <div class="container px-3">
          <div class="row g-5">
            <div class="single-page fromData">
              <div class="single-page-artical">
                <div class="artical-content">
                  <!-- 判斷有無影片 -->
                  <div class="iframeBox" v-if="pageData.youtube">
                    <!-- <iframe
                      :src="
                        'https://www.youtube.com/embed/' +
                        pageData.youtube.split('=')[1]
                      "
                      frameborder="0"
                    /> -->
                    <template v-if="pageData.youtube.includes('watch')">
                      <template
                        v-if="pageData.youtube.split('=')[1].includes('&')"
                      >
                        <iframe
                          :src="
                            'https://www.youtube.com/embed/' +
                            pageData.youtube.split('=')[1].split('&')[0]
                          "
                          frameborder="0"
                        />
                      </template>
                      <template v-else>
                        <iframe
                          :src="
                            'https://www.youtube.com/embed/' +
                            pageData.youtube.split('=')[1]
                          "
                          frameborder="0"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <iframe
                        :src="
                          'https://www.youtube.com/embed/' +
                          pageData.youtube.split('be/')[1]
                        "
                        frameborder="0"
                      />
                    </template>
                  </div>

                  <div class="pageTitle">
                    <div class="floatLeft">
                      <h3>
                        <a href="#">
                          <span class="titleFont">{{ pageData.title }}</span>
                        </a>
                      </h3>
                    </div>
                    <div class="floatRight typeIcon">
                      <img src="../assets/img/blog-icon4.png" />
                      <span>{{ pageData.type }}</span>
                    </div>
                  </div>
                  <div class="clearBoth"></div>
                  <div class="pageCompany" v-if="pageData.number">
                    <span>活動編號:</span>
                    {{ pageData.number.toString().padStart(5, '0') }}
                    <!-- {{ typeof pageData.number }} -->
                  </div>
                  <div class="pageCompany">
                    <span>代表公司:</span>{{ pageData.company }}
                  </div>
                  <div class="pageCompany">
                    <span>姓名:</span>{{ pageData.name }}
                  </div>
                  <div class="pageCompany">
                    <span>聯絡方式:</span>{{ pageData.connection }}
                  </div>
                  <div class="pageCompany">
                    <span>服務群組:</span>
                    <a
                      :href="pageData.group"
                      target="_blank"
                      v-if="pageData.group"
                      >{{ pageData.group }}</a
                    >
                  </div>
                  <div class="pageCompany">
                    <span>團購表單:</span>

                    <a
                      :href="pageData.form"
                      target="_blank"
                      v-if="pageData.form"
                      >網址</a
                    >
                  </div>
                  <div class="pageCompany">
                    <span>公司網址:</span>
                    <a :href="pageData.companyURL" target="_blank">{{
                      pageData.companyURL
                    }}</a>
                  </div>
                  <div class="pageCompany" v-if="pageData.sales_deadline">
                    <span>優惠截止日:</span
                    >{{ pageData.sales_deadline.slice(0, 10) }}
                  </div>
                  <div
                    class="para1 pageContent"
                    v-html="pageData.content"
                  ></div>

                  <div v-if="pageData.data">
                    <div class="imgCon">
                      <div
                        class="pageImgBorder"
                        v-for="(item, index) in downloadData"
                      >
                        <div class="pageImg">
                          <a :href="item" target="_blank" class="pageImg">
                            <img
                              :src="
                                'https://drive.google.com/uc?export=view&id=' +
                                item.split('=')[1]
                              "
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pageImgBorder" v-else>
                    <div class="pageImg">
                      <img src="../assets/img/emba.jpg" />
                    </div>
                  </div>
                </div>

                <div class="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!---//End-content---->

    <!---//End-wrap---->
  </div>
</template>
<script>
$(document).ready(function () {
  //Hide (Collapse) the toggle containers on load
})
</script>

<script>
import '../assets/css/style.css'
import '../assets/css/main.css'
import mix from './mixinData.vue'
export default {
  mixins: [mix],
  name: 'Page',
  data() {
    return {
      pageData: '',
      number: this.$route.params.number,
      downloadData: [],
      isLoading: false,
    }
  },
  components: {},
  mounted() {
    // console.log(this.isLoading, 'isLoading')
    // this.isLoading = true
    // $('.toggle_container').hide()
    // //Switch the "Open" and "Close" state per click then slide up/down (depending on open/close state)
    // $('.trigger').click(function () {
    //   $(this).toggleClass('active').next().slideToggle('slow')
    //   return false //Prevent the browser jump to the link anchor
    // })
    $('.fromData').css('visibility', 'hidden')
  },
  watch: {
    async info() {
      // 資料內容
      await this.info
      this.info.forEach((element) => {
        if (element.number == this.number) {
          element.content = element.content.replace(
            new RegExp(';', 'g'),
            '<br/>'
          )
          var contentData = element.content.match(
            /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/gm
          )
          //   console.log('url', contentData)
          if (contentData) {
            contentData.forEach((url) => {
              //   console.log('url', url)
              element.content = element.content.replace(
                new RegExp(url, 'g'),
                '<a href="' + url + '" target="_blank">' + url + '</a>'
              )
            })
          }
          //   element.content = element.content.replace(
          //     new RegExp(contentData, 'g'),
          //     '<a href="' +
          //       contentData +
          //       '" target="_blank">' +
          //       contentData +
          //       '</a>'
          //   )
          //
          this.pageData = element
        }
      })
      this.isLoading = false
      // 附加檔案
      if (this.pageData.data) {
        this.downloadData = this.pageData.data.split(',')
      } else {
        this.downloadData = ''
      }
    },
  },
  methods: {},
  created() {},
}
</script>
